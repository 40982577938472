<template>
  <pv-dialog header="音声データ削除" v-model:visible="confirmDelete" modal :closable="false">
    <div class="confirmation-content">
      <span>
        <template v-for="l in labels" :key="l.id">
          {{ getAttr(subject.attrs, l)?.value }} /
        </template>
        を削除します。<br />よろしいですか？
      </span>
    </div>
    <template #footer>
      <pv-button label="取消" class="p-button-text" @click="confirmDelete = false" />
      <pv-button label="削除" @click="removeSubject" />
    </template>
  </pv-dialog>
</template>

<script>
import { computed } from "vue";
import { useClientLabels, deleteSubject } from "@/common/api";

export default {
  props: {
    modelValue: Boolean,
    subject: Object,
  },
  emits: ["delete", "update:modelValue"],
  setup(props, { emit }) {
    const { labels, getAttr } = useClientLabels();

    const confirmDelete = computed({
      get: () => props.modelValue,
      set: (val) => {
        emit("update:modelValue", val);
      }
    });

    async function removeSubject() {
      await deleteSubject(props.subject.id);
      confirmDelete.value = false;
      emit("delete");
    }

    return { confirmDelete, labels, getAttr, removeSubject };
  }
}
</script>