<template>
  <pv-dialog v-model:visible="subjectDialog" :header="subject.id ? '音声情報編集' : '音声情報登録'" modal :closable="false" :style="{width: '440px'}">
    <div v-if="subject?.voice" class="mb-3 font-bold">
      <span v-for="id in ids" :key="id.id">{{ subject['id-'+id.id] }}&nbsp;</span>
      <span v-for="attr in attrs" :key="attr.id">{{ subject['attr-'+attr.id] }}&nbsp;</span>
      <div class="mt-1">
        {{ subject.voice.filename }}
      </div>
    </div>
    <form @submit.prevent="updateSubject">
      <div class="formgrid grid mb-3">
        <input v-show="false" type="file" ref="uploadButton" @change="setFile" />
        <pv-button :label="filename ? filename : '音声データを選択'" icon="pi pi-folder-open" @click="$refs.uploadButton.click()" />
      </div>
      <div v-if="filename" class="formgrid grid mb-3">
        <label class="col-4 input-label">取得日時</label>
        <calendar class="col-8" v-model="recorded_at" showTime show-icon full />
      </div>
      <div v-for="id in ids" :key="id.id" class="formgrid grid">
        <label class="col-4 input-label">{{ id.name }}</label>
        <div class="field col-8">
          <pv-input type="text" v-model="form['id-'+id.id]" :error="error['id-'+id.id]" full required />
        </div>
      </div>
      <div v-for="attr in attrs" :key="attr.id" class="formgrid grid">
        <label class="col-4 input-label">
          {{ attr.name }}<br />
          <small class="p-error">{{ dispRule(attr) }}</small>
        </label>
        <div class="field col-8">
          <pv-input type="text" v-model="form['attr-'+attr.id]" :error="error['attr-'+attr.id]" full />
        </div>
      </div>

      <input v-show="false" ref="formSubmit" type="submit" />
    </form>

    <pv-panel v-if="subject.history > 0" header="変更履歴" toggleable collapsed>
      <div v-for="attr in subject.history" :key="attr.id">
        {{ dayjs(attr.created_at).format('YYYY/MM/DD HH:mm') }}
        {{ attr.client_label.name }}
        {{ attr.rev == 1 ? '登録' : '更新' }} ({{ attr.value }})
        (編集: {{ attr.user.login_id }})
      </div>
    </pv-panel>

    <template #footer>
      <pv-button label="取消" class="p-button-text" @click="cancel" />
      <pv-button :label="subject.id ? '更新' : '登録'" @click="formSubmit.click()" />
    </template>
  </pv-dialog>
</template>

<script>
import { ref, computed, watch } from "vue";
import { useClientLabels, postSubject } from "@/common/api";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const HIRA = '[\\u{3000}-\\u{301C}\\u{3041}-\\u{3093}\\u{309B}-\\u{309E}]';
const KATA = '[\\u{3000}-\\u{301C}\\u{30A1}-\\u{30F6}\\u{30FB}-\\u{30FE}]';
const KATA_HAN = '[ｦ-ﾟ]';
const ASCII = '[Ａ-Ｚａ-ｚ]';
const ASCII_HAN = '[A-Za-z]';
const SIGN = '[！＠＃＄％＾＆＊（）＿＋－＝［］｛｝；：？，．＼｜]';
const SIGN_HAN = '[!@#$%^&*()_+\\-=[\\]{};:?,.\\|]';
const DIGIT = '[０-９]';
const DIGIT_HAN = '[0-9]';

export default {
  props: {
    modelValue: Boolean,
    subject: Object,
  },
  emits: ["change", "update:modelValue"],
  setup(props, { emit }) {
    const recorded_at = ref();
    const error = ref({});
    const formSubmit = ref();

    const subjectDialog = computed({
      get: () => props.modelValue,
      set: (val) => {
        emit("update:modelValue", val);
      }
    });

    const form = ref({});
    watch(() => props.subject, (s) => {
      form.value = { ...s };
      if (s.voice?.recorded_at) {
        recorded_at.value = new Date(s.voice?.recorded_at);
      }
    });
    watch(() => props.modelValue, (v) => {
      if (!v) {
        form.value = {};
        error.value = {};
      }
    });
    const { labels, ids, attrs } = useClientLabels();
    const filename = ref();

    const file = ref();
    function setFile(e) {
      file.value = e.target.files[0];
      filename.value = file.value.name;
      recorded_at.value = new Date(file.value.lastModified);
    }

    function setError(label, msg) {
      error.value[`${label.type}-${label.id}`] = msg;
    }

    function cancel() {
      file.value = {};
      recorded_at.value = "";
      filename.value = "";
      form.value = {};
      error.value = {};
      subjectDialog.value = false;
    }

    async function updateSubject() {
      let params = new FormData();

      if (file.value) {
        params.append("file", file.value);
        params.append("recorded_at", dayjs(recorded_at.value).utc().format("YYYY-MM-DD HH:mm"));
      } else if (recorded_at.value) {
        params.append("recorded_at", dayjs(recorded_at.value).utc().format("YYYY-MM-DD HH:mm"));
      } else {
        if (!form.value.id) {
          alert("音声ファイルを登録して下さい");
          return;
        }
      }

      error.value = {};
      for (let label of labels.value) {
        let val = form.value[`${label.type}-${label.id}`];

        if (val) {
          params.append(`${label.type}-${label.id}`, form.value[`${label.type}-${label.id}`]);
        }

        if (label.type == 'id' || label.required) {
          // 必須チェック
          if (!val) {
            setError(label, `入力して下さい`);
            continue;
          }
        }

        if (val && label.min_length) {
          if (val.length < label.min_length) {
            setError(label, `${label.min_length}文字以上入力して下さい`);
            continue;
          }
        }
        if (val && label.max_length) {
          if (val.length > label.max_length) {
            setError(label, `${label.max_length}文字以下にして下さい`);
            continue;
          }
        }

        if (label.char_type.includes('all')) {
          continue;
        }

        let chars = [];
        let vcinfo = [];
        if (val && label.char_type.includes('hira')) {
          chars.push(HIRA);
          vcinfo.push('全角ひらがな');
        }
        if (val && label.char_type.includes('kana') && label.char_width.includes('full')) {
          chars.push(KATA);
          vcinfo.push('全角カタカナ');
        }
        if (val && label.char_type.includes('kana') && label.char_width.includes('half')) {
          chars.push(KATA_HAN);
          vcinfo.push('半角カタカナ');
        }
        if (val && label.char_type.includes('alphabet') && label.char_width.includes('full')) {
          chars.push(ASCII);
          vcinfo.push('全角英字');
        }
        if (val && label.char_type.includes('alphabet') && label.char_width.includes('half')) {
          chars.push(ASCII_HAN);
          vcinfo.push('半角英字');
        }
        if (val && label.char_type.includes('sign') && label.char_width.includes('full')) {
          chars.push(SIGN);
          vcinfo.push('全角記号');
        }
        if (val && label.char_type.includes('sign') && label.char_width.includes('half')) {
          chars.push(SIGN_HAN);
          vcinfo.push('半角記号');
        }
        if (val && label.char_type.includes('digit') && label.char_width.includes('full')) {
          chars.push(DIGIT);
          vcinfo.push('全角数字');
        }
        if (val && label.char_type.includes('digit') && label.char_width.includes('half')) {
          chars.push(DIGIT_HAN);
          vcinfo.push('半角数字');
        }

        let regexp = new RegExp('^('+chars.join('|')+')+$', 'mu');
        if (val && !regexp.test(val)) {
          setError(label, vcinfo.join('、')+'以外の文字が使われています');
          continue;
        }
      }

      if (Object.keys(error.value).length > 0) return;

      await postSubject(params, form.value.id);
      emit("change");

      subjectDialog.value = false;
    }

    function dispRule(attr) {
      let rule = [];

      if (attr.required) rule.push("必須");
      if (attr.min_length) rule.push(`${attr.min_length}文字以上`);
      if (attr.max_length) rule.push(`${attr.max_length}文字以下`);

      if (!attr.char_type.includes('all')) {
        if (attr.char_type.includes('kana') && attr.char_width.includes('full')) {
          rule.push('全角カタカナ');
        }
        if (attr.char_type.includes('kana') && attr.char_width.includes('half')) {
          rule.push('半角カタカナ');
        }
        if (attr.char_type.includes('alphabet') && attr.char_width.includes('full')) {
          rule.push('全角英字');
        }
        if (attr.char_type.includes('alphabet') && attr.char_width.includes('half')) {
          rule.push('半角英字');
        }
        if (attr.char_type.includes('sign') && attr.char_width.includes('full')) {
          rule.push('全角記号');
        }
        if (attr.char_type.includes('sign') && attr.char_width.includes('half')) {
          rule.push('半角記号');
        }
        if (attr.char_type.includes('digit') && attr.char_width.includes('full')) {
          rule.push('全角数字');
        }
        if (attr.char_type.includes('digit') && attr.char_width.includes('half')) {
          rule.push('半角数字');
        }
      }

      return rule.join(' / ');
    }

    return {
      recorded_at, error, formSubmit, subjectDialog, form, cancel,
      labels, ids, attrs, filename, setFile, updateSubject,
      dispRule,
    };
  }
}
</script>